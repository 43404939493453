html,
body {
    margin: 0;
}
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.logo {
    margin: 8px;
    width: 128px;
}
.logo-sm {
    margin: 8px;
    width: 128px;
}
* {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
        -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
}
.index-sheet {
    width: 300px;
}
.value-viewer {
    padding-right: 8px;
}
